/* eslint-disable no-dupe-class-members */
const { ROUTE_CONFIGURATIONS } = require('operabase-router/constants/configurations');
const { ROUTE_RESERVED_KEYWORDS, FILTER_TYPES, ENTITY_TYPES, PROFESSION_TYPES } = require('../constants');
const { RESERVED_TRANSLATIONS, SEO_TRANSLATIONS } = require('../constants/seoTranslations');
const getEntityName = require('../utils/getEntityName');

class PageTitleResolver {
  #getDateFilterString = null;

  #processedFilters = {};

  #hasTickets = false;

  #hasWatchOnline = false;

  #hasUpcomingPerformances = false;

  #globalStats = {};

  constructor({
    getDateFilterString,
    processedFilters,
    hasTickets,
    hasWatchOnline,
    hasUpcomingPerformances,
    globalStats = {},
  } = {}) {
    this.#getDateFilterString = getDateFilterString;
    this.#processedFilters = processedFilters;
    this.#hasTickets = hasTickets;
    this.#hasWatchOnline = hasWatchOnline;
    this.#hasUpcomingPerformances = hasUpcomingPerformances;
    this.#globalStats = globalStats;
  }

  #getProductionListingTitle({ filters }) {
    const {
      [FILTER_TYPES.WHO]: whoFilters,
      [FILTER_TYPES.GENRE]: genreFilters,
      [FILTER_TYPES.WHAT]: whatFilters,
      [FILTER_TYPES.WHERE]: whereFilters,
    } = filters;

    const hasWhoFilters = whoFilters?.length > 0;
    const hasGenreFilters = genreFilters?.length > 0;
    const hasWhatFilters = whatFilters?.length > 0;
    const hasWhereFilters = whereFilters?.length > 0;
    const hasSearchFilters = hasWhoFilters || hasGenreFilters || hasWhatFilters || hasWhereFilters;

    let baseKey = SEO_TRANSLATIONS.PRODUCTIONS.title;
    let mainEntityName;

    if (whoFilters?.length === 1) {
      const filterType = whoFilters?.length === 1 ? FILTER_TYPES.WHO : FILTER_TYPES.WHERE;
      const { [filterType]: _filterTypeValues, ...otherFilters } = this.#processedFilters;
      const mainEntity = filters[filterType][0];
      baseKey =
        mainEntity?.entityType === ENTITY_TYPES.PROFILE
          ? SEO_TRANSLATIONS.PROFILE_PERFORMANCES_NO_FILTERS.title
          : SEO_TRANSLATIONS.ORGANIZATION_PERFORMANCES_NO_FILTERS.title;
      mainEntityName = getEntityName(mainEntity, { withDetails: true });

      if (hasGenreFilters && hasWhatFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_GENRE_WHAT_WHERE.title;
      } else if (hasWhereFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_WHAT_WHERE.title;
      } else if (hasGenreFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_GENRE_WHERE.title;
      } else if (hasGenreFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_GENRE_WHAT.title;
      } else if (hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_WHERE.title;
      } else if (hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_WHAT.title;
      } else if (hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_GENRE.title;
      }

      return [
        {
          key: baseKey,
          params: {
            ...otherFilters,
            entity: mainEntityName,
          },
        },
      ];
    }

    if (whereFilters?.length === 1) {
      const { [FILTER_TYPES.WHERE]: _whereFilter, ...otherFilters } = this.#processedFilters;
      baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_NO_FILTERS.title;
      mainEntityName = getEntityName(whereFilters[0], { withDetails: true });

      if (hasWhoFilters && hasWhatFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHO_GENRE_WHAT.title;
      } else if (hasWhoFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHO_WHAT.title;
      } else if (hasWhoFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHO_GENRE.title;
      } else if (hasWhatFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_GENRE_WHAT.title;
      } else if (hasWhoFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHO.title;
      } else if (hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHAT.title;
      } else if (hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_GENRE.title;
      }

      return [
        {
          key: baseKey,
          params: {
            ...otherFilters,
            location: mainEntityName,
          },
        },
      ];
    }

    if (whatFilters?.length === 1) {
      const { [FILTER_TYPES.WHAT]: _whatFilter, ...otherFilters } = this.#processedFilters;
      baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_NO_FILTERS.title;
      mainEntityName = getEntityName(whatFilters[0], { withDetails: true });

      if (hasWhoFilters && hasWhereFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHO_GENRE_WHERE.title;
      } else if (hasWhoFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHO_WHERE.title;
      } else if (hasWhoFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHO_GENRE.title;
      } else if (hasWhereFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_GENRE_WHERE.title;
      } else if (hasWhoFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHO.title;
      } else if (hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHERE.title;
      } else if (hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_GENRE.title;
      }

      return [
        {
          key: baseKey,
          params: {
            ...otherFilters,
            work: mainEntityName,
          },
        },
      ];
    }

    if (genreFilters?.length === 1) {
      const { [FILTER_TYPES.WORK_TYPE]: _workTypeFilter, ...otherFilters } = this.#processedFilters;
      baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_NO_FILTERS.title;
      mainEntityName = getEntityName(genreFilters[0], { withDetails: true });

      if (hasWhoFilters && hasWhatFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHO_WHAT_WHERE.title;
      } else if (hasWhoFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHO_WHERE.title;
      } else if (hasWhereFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHAT_WHERE.title;
      } else if (hasWhoFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHO_WHAT.title;
      } else if (hasWhoFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHO.title;
      } else if (hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHAT.title;
      } else if (hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHERE.title;
      }

      return [
        {
          key: baseKey,
          params: {
            ...otherFilters,
            genre: mainEntityName,
          },
        },
      ];
    }

    if (hasSearchFilters) {
      const {
        [FILTER_TYPES.WHO]: whoProcessedFilters,
        [FILTER_TYPES.GENRE]: genreProcessedFilters,
        [FILTER_TYPES.WHAT]: whatProcessedFilters,
        [FILTER_TYPES.WHERE]: whereProcessedFilters,
      } = this.#processedFilters;

      const sortedFilters = [
        whoProcessedFilters,
        whereProcessedFilters,
        whatProcessedFilters,
        genreProcessedFilters,
      ].filter(Boolean);

      const dateFilter = this.#getDateFilterString(filters[FILTER_TYPES.DATE]);

      return [
        ...sortedFilters.map(values => ({
          key: values,
        })),
        ...(dateFilter ? [{ key: dateFilter }] : []),
      ];
    }

    return [
      {
        key: SEO_TRANSLATIONS.PRODUCTIONS.title,
        params: {
          filters,
        },
      },
    ];
  }

  #getPageEntityAndSearchTitle({ page, linkProps }) {
    const { entity, entityType, tab, searchable } = page;
    const { basePath, filters } = linkProps || {};
    const hasAppliedFilters = Object.keys(filters || {}).length > 0;

    if (basePath === ROUTE_RESERVED_KEYWORDS.artists) {
      const profession = filters?.[FILTER_TYPES.PROFESSION]?.[0]?.entity;
      let titleKey;
      let params = {};

      if (!profession) {
        const artistsString = this.#globalStats.artists?.toString() || '';
        const count = artistsString ? Math.round(Number(artistsString.replace(/[^\d]/g, '')) / 1000) * 1000 : '';

        titleKey = SEO_TRANSLATIONS.ARTISTS.title;
        params = { count };
      } else {
        params = { profession: profession.name };

        if (profession.type === PROFESSION_TYPES.VOICE_TYPE) {
          titleKey = SEO_TRANSLATIONS.ARTISTS_VOICE_TYPE.title;
        } else if (profession.type === PROFESSION_TYPES.INSTRUMENTALIST) {
          titleKey = SEO_TRANSLATIONS.ARTISTS_INSTRUMENTALIST.title;
        } else {
          titleKey = SEO_TRANSLATIONS.ARTISTS_OTHER.title;
        }
      }

      return [
        {
          key: titleKey,
          params,
        },
      ];
    }

    if (basePath === ROUTE_RESERVED_KEYWORDS.organisations) {
      const cityFilter = filters?.[FILTER_TYPES.CITY]?.[0];
      const countryFilter = filters?.[FILTER_TYPES.COUNTRY]?.[0];
      const orgType = filters?.[FILTER_TYPES.ORGANIZATION_TYPE]?.[0]?.entity;

      const city = cityFilter?.entity;
      const country =
        countryFilter?.entity ||
        cityFilter?.parents?.find(parent => parent.entityType === ENTITY_TYPES.COUNTRY)?.entity;

      let titleKey;
      let params = {};

      const orgsString = this.#globalStats.organizations?.toString() || '';
      const count = orgsString ? Math.round(Number(orgsString.replace(/[^\d]/g, '')) / 1000) * 1000 : '';

      if (orgType) {
        if (country || city) {
          titleKey = RESERVED_TRANSLATIONS.ORG_TYPE_IN_LOCATION;

          let location = '';
          if (city && country) {
            location = `${city.name}, ${country.name}`;
          } else if (city) {
            location = city.name;
          } else if (country) {
            location = country.name;
          }

          params = {
            orgType: orgType.name,
            location,
            count,
          };
        } else {
          titleKey = orgType.name;
          params = { count };
        }
      } else {
        titleKey = SEO_TRANSLATIONS.ORGANIZATIONS.title;
        params = { count };
      }

      return [
        {
          key: titleKey,
          params,
        },
      ];
    }

    if (basePath === ROUTE_RESERVED_KEYWORDS.managers) {
      if (page?.entity) {
        return [
          {
            key: page.entity.name,
          },
        ];
      }

      const country = filters?.[FILTER_TYPES.COUNTRY]?.[0]?.entity;
      let titleKey;
      let params = {};

      if (country) {
        titleKey = SEO_TRANSLATIONS.MANAGERS_COUNTRY.title;
        params = { managerCountry: country.name };
      } else {
        const managersString = this.#globalStats.managers?.toString() || '';

        titleKey = SEO_TRANSLATIONS.MANAGERS.title;
        params = { count: managersString };
      }

      return [
        {
          key: titleKey,
          params,
        },
      ];
    }

    if (searchable) {
      return this.#getProductionListingTitle({ filters, page });
    }

    if (entityType && !!entity?.id) {
      switch (tab) {
        default: {
          if (tab) {
            return [
              {
                key: RESERVED_TRANSLATIONS.ENTITY_TAB,
                params: {
                  entity: entity.name,
                  tab: {
                    key: ROUTE_CONFIGURATIONS[tab]?.translationKey,
                  },
                },
              },
            ];
          }

          return [
            {
              key: entity?.name,
            },
          ];
        }
      }
    }

    if (!basePath && hasAppliedFilters) {
      return [
        {
          key: SEO_TRANSLATIONS.PRODUCTIONS.title,
          params: {
            filters,
          },
        },
      ];
    }

    if (basePath) {
      return [
        {
          key: basePath,
        },
      ];
    }

    return [
      {
        key: SEO_TRANSLATIONS.HOMEPAGE.title,
      },
    ];
  }

  #getSecondaryTitleParts({ page, skipTitleObSuffix = false }) {
    const { tab, searchable } = page;

    if (searchable) {
      const hasTickets = this.#hasTickets;
      const hasWatchOnline = this.#hasWatchOnline;

      if (hasTickets && hasWatchOnline) {
        return [
          {
            key: RESERVED_TRANSLATIONS.TICKETS_ONLINE,
          },
        ];
      }

      if (hasTickets) {
        return [{ key: RESERVED_TRANSLATIONS.TICKETS_AVAILABLE }];
      }

      if (hasWatchOnline) {
        return [{ key: RESERVED_TRANSLATIONS.WATCH_ONLINE }];
      }

      const hasUpcomingPerformances = this.#hasUpcomingPerformances;

      if (hasUpcomingPerformances) {
        return [{ key: RESERVED_TRANSLATIONS.UPCOMING_PERFORMANCES }];
      }

      if (!tab || [ROUTE_RESERVED_KEYWORDS.performances].includes(tab)) {
        return [{ key: RESERVED_TRANSLATIONS.PAST_PERFORMANCES }];
      }
    }

    if (!skipTitleObSuffix) {
      return [
        {
          key: RESERVED_TRANSLATIONS.OPERABASE,
        },
      ];
    }

    return [];
  }

  getTitle({ page, linkProps, skipTitleObSuffix }) {
    const pageEntityAndSearchTitle = this.#getPageEntityAndSearchTitle({ page, linkProps });

    const secondaryTitleParts = this.#getSecondaryTitleParts({ page, linkProps, skipTitleObSuffix });

    return [...pageEntityAndSearchTitle, ...secondaryTitleParts];
  }
}

module.exports = PageTitleResolver;
