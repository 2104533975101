import React, { useMemo, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { NextSeo } from 'next-seo';
import { useQuery } from 'utils/react-query';
import useAppContext from 'utils/hooks/useAppContext';
import usePageContext from 'utils/hooks/usePageContext';
import queries from 'containers/Globals/queries';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from 'operabase-router/constants';
import { getPagePathnameWithLanguage } from 'operabase-router/utils';
import { useRecursiveTranslation } from 'src/i18n';

import MetaDetailsResolver from 'operabase-router/handlers/MetaDetailsResolver';

require('dotenv').config();

const SEO = () => {
  const { obRouteContext } = useAppContext();
  const { entityType, entity } = usePageContext();
  const { tRecursive } = useRecursiveTranslation('NS_APP_GLOBALS');
  const { data: globalStats } = useQuery(
    queries.getHomeNumbers({
      queryConfig: {
        select: response =>
          Object.keys(response?.data).reduce((acc, key) => {
            const count = response.data[key].total;

            if (count > 0) {
              acc[key] = count.toLocaleString();
            }

            return acc;
          }, {}),
      },
    }),
  );

  const seo = useMemo(() => {
    const metaDetailsResolver = new MetaDetailsResolver(obRouteContext, { entityType, entity, globalStats });

    return metaDetailsResolver.getDetails();
  }, [obRouteContext, entityType, entity, globalStats]);

  const processNestedTranslations = useCallback(
    translations =>
      translations
        .reduce((acc, value) => {
          let translatedValue = tRecursive(value) || '';

          if (typeof translatedValue === 'string') {
            translatedValue = translatedValue.replace(/\.$/, '');
            translatedValue = translatedValue.trim();

            acc.push(translatedValue);
          }

          return acc;
        }, [])
        .join(' | ')
        .replace(/\s+/g, ' '),
    [tRecursive],
  );

  const seoProps = useMemo(() => {
    const { canonical, openGraph } = seo || {};

    const languageAlternates = SUPPORTED_LANGUAGES.reduce((acc, langCode) => {
      if (langCode === 'keys' || !canonical) {
        return acc;
      }

      const pathname = getPagePathnameWithLanguage(canonical, langCode);

      if (langCode === DEFAULT_LANGUAGE) {
        acc.push({
          hrefLang: 'x-default',
          href: pathname,
        });
      }

      acc.push({
        hrefLang: langCode,
        href: pathname,
      });

      return acc;
    }, []);

    const titleString = processNestedTranslations(seo?.title);

    const descriptionString = processNestedTranslations(seo?.description);

    return {
      ...(seo || {}),
      title: titleString,
      description: descriptionString,
      languageAlternates,
      twitter: {
        title: openGraph?.title || titleString,
        description: openGraph?.description || descriptionString,
        card: 'summary_large_image',
        site: '@operabase_',
        creator: '@operabase_',
      },
      additionalMetaTags: [
        {
          name: 'msapplication-TileColor',
          content: '#f36d2a',
        },
        {
          name: 'msapplication-TileImage',
          content: `${process.env.FRONTBASE_URL}/images/favicon/mstile-144x144.png`,
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
      ],
    };
  }, [seo, processNestedTranslations]);

  return <NextSeo {...seoProps} />;
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ogImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ),
  disableQuery: PropTypes.bool,
  pageQueryList: PropTypes.arrayOf(PropTypes.string),
};

SEO.defaultProps = {
  ogImages: [],
  disableQuery: true,
  pageQueryList: [],
};

export default memo(SEO);
