const { ENTITY_TYPES, ATTRIBUTE_TYPES } = require('../constants');

const getEntityName = ({ entity, entityType, attributes = {}, format } = {}, options) => {
  const { withDetails = true } = options || {};

  switch (entityType) {
    case ENTITY_TYPES.PROFILE: {
      let professions = null;

      if (withDetails) {
        professions = attributes[ATTRIBUTE_TYPES.ENTITY]
          .reduce((acc, item) => {
            if (item.entityType === ENTITY_TYPES.PROFESSION) {
              acc.push(getEntityName(item, options));
            }

            return acc;
          }, [])
          .join(', ');
      }

      if (professions) {
        return `${entity?.name || ''} (${professions})`;
      }

      return entity?.name || '';
    }
    case ENTITY_TYPES.ORGANIZATION: {
      let location = null;

      if (withDetails) {
        const city = attributes[ATTRIBUTE_TYPES.ENTITY].find(item => item.entityType === ENTITY_TYPES.CITY);
        const country = attributes[ATTRIBUTE_TYPES.ENTITY].find(item => item.entityType === ENTITY_TYPES.COUNTRY);

        location = [getEntityName(city), getEntityName(country)].filter(Boolean).join(', ');
      }

      if (location) {
        return `${entity?.name || ''}, ${location}`;
      }

      return entity?.name || '';
    }
    case ENTITY_TYPES.WORK_TYPE: {
      let stagingType = null;

      if (withDetails && format) {
        stagingType = getEntityName({ entityType: ENTITY_TYPES.STAGING_TYPE, entity: format });
      }

      if (stagingType) {
        return `${entity?.name || ''} (${stagingType})`;
      }

      return entity?.name || '';
    }
    case ENTITY_TYPES.STAGING_TYPE:
    case ENTITY_TYPES.PROFESSION:
    default:
      return entity?.name || '';
  }
};

module.exports = getEntityName;
