/* eslint-disable no-dupe-class-members */
const { ROUTE_CONFIGURATIONS } = require('operabase-router/constants/configurations');
const { FILTER_TYPES, ENTITY_TYPES, ROUTE_RESERVED_KEYWORDS, PROFESSION_TYPES } = require('../constants');
const { RESERVED_TRANSLATIONS, SEO_TRANSLATIONS } = require('../constants/seoTranslations');
const getEntityName = require('../utils/getEntityName');

class PageDescResolver {
  #getDateFilterString = null;

  #processedFilters = {};

  #hasTickets = false;

  #hasWatchOnline = false;

  #hasUpcomingPerformances = false;

  #globalStats = {};

  constructor({
    getDateFilterString,
    processedFilters,
    hasTickets,
    hasWatchOnline,
    hasUpcomingPerformances,
    globalStats,
  } = {}) {
    this.#getDateFilterString = getDateFilterString;
    this.#processedFilters = processedFilters;
    this.#hasTickets = hasTickets;
    this.#hasWatchOnline = hasWatchOnline;
    this.#hasUpcomingPerformances = hasUpcomingPerformances;
    this.#globalStats = globalStats;
  }

  #getProductionListingDesc({ filters, page }) {
    const {
      [FILTER_TYPES.WHO]: whoFilters,
      [FILTER_TYPES.GENRE]: genreFilters,
      [FILTER_TYPES.WHAT]: whatFilters,
      [FILTER_TYPES.WHERE]: whereFilters,
    } = filters;

    const hasWhoFilters = whoFilters?.length > 0;
    const hasGenreFilters = genreFilters?.length > 0;
    const hasWhatFilters = whatFilters?.length > 0;
    const hasWhereFilters = whereFilters?.length > 0;
    const hasSearchFilters = hasWhoFilters || hasGenreFilters || hasWhatFilters || hasWhereFilters;

    let baseKey = SEO_TRANSLATIONS.PRODUCTIONS.title;
    let mainEntityName;

    if (whoFilters?.length === 1) {
      const filterType = whoFilters?.length === 1 ? FILTER_TYPES.WHO : FILTER_TYPES.WHERE;
      const { [filterType]: _filterTypeValues, ...otherFilters } = this.#processedFilters;
      const mainEntity = filters[filterType][0];
      baseKey =
        mainEntity?.entityType === ENTITY_TYPES.PROFILE
          ? SEO_TRANSLATIONS.PROFILE_PERFORMANCES_NO_FILTERS.description
          : SEO_TRANSLATIONS.ORGANIZATION_PERFORMANCES_NO_FILTERS.description;

      const noOtherSearchFiltersApplied = !hasGenreFilters && !hasWhatFilters && !hasWhereFilters;

      mainEntityName = getEntityName(mainEntity, { withDetails: true });

      if (hasGenreFilters && hasWhatFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_GENRE_WHAT_WHERE.title;
      } else if (hasWhereFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_WHAT_WHERE.title;
      } else if (hasGenreFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_GENRE_WHERE.title;
      } else if (hasGenreFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_GENRE_WHAT.title;
      } else if (hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_WHERE.title;
      } else if (hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_WHAT.title;
      } else if (hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.ENTITY_PERFORMANCES_GENRE.title;
      }

      let location = null;

      if (noOtherSearchFiltersApplied && mainEntity?.entityType === ENTITY_TYPES.ORGANIZATION) {
        const { city, country } = page?.entity;

        const cityName = getEntityName({ entityType: ENTITY_TYPES.CITY, entity: city });
        const countryName = getEntityName({ entityType: ENTITY_TYPES.COUNTRY, entity: country });

        location = [cityName, countryName].filter(Boolean).join(', ');
      }

      return [
        {
          key: baseKey,
          params: {
            ...otherFilters,
            ...(noOtherSearchFiltersApplied && { count: page?.entity?.stats?.performances?.total || '' }),
            ...(noOtherSearchFiltersApplied && mainEntity?.entityType === ENTITY_TYPES.ORGANIZATION && { location }),
            entity: mainEntityName,
          },
        },
      ];
    }

    if (whereFilters?.length === 1) {
      const { [FILTER_TYPES.WHERE]: _whereFilter, ...otherFilters } = this.#processedFilters;
      baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_NO_FILTERS.title;
      mainEntityName = getEntityName(whereFilters[0], { withDetails: true });

      if (hasWhoFilters && hasWhatFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHO_GENRE_WHAT.title;
      } else if (hasWhoFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHO_WHAT.title;
      } else if (hasWhoFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHO_GENRE.title;
      } else if (hasWhatFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_GENRE_WHAT.title;
      } else if (hasWhoFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHO.title;
      } else if (hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_WHAT.title;
      } else if (hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHERE_PERFORMANCES_GENRE.title;
      }

      return [
        {
          key: baseKey,
          params: {
            ...otherFilters,
            location: mainEntityName,
          },
        },
      ];
    }

    if (whatFilters?.length === 1) {
      const { [FILTER_TYPES.WHAT]: _whatFilter, ...otherFilters } = this.#processedFilters;
      baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_NO_FILTERS.title;
      mainEntityName = getEntityName(whatFilters[0], { withDetails: true });

      if (hasWhoFilters && hasWhereFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHO_GENRE_WHERE.title;
      } else if (hasWhoFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHO_WHERE.title;
      } else if (hasWhoFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHO_GENRE.title;
      } else if (hasWhereFilters && hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_GENRE_WHERE.title;
      } else if (hasWhoFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHO.title;
      } else if (hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_WHERE.title;
      } else if (hasGenreFilters) {
        baseKey = SEO_TRANSLATIONS.WHAT_PERFORMANCES_GENRE.title;
      }

      return [
        {
          key: baseKey,
          params: {
            ...otherFilters,
            work: mainEntityName,
          },
        },
      ];
    }

    if (genreFilters?.length === 1) {
      const { [FILTER_TYPES.WORK_TYPE]: _workTypeFilter, ...otherFilters } = this.#processedFilters;
      baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_NO_FILTERS.title;
      mainEntityName = getEntityName(genreFilters[0], { withDetails: true });

      if (hasWhoFilters && hasWhatFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHO_WHAT_WHERE.title;
      } else if (hasWhoFilters && hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHO_WHERE.title;
      } else if (hasWhereFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHAT_WHERE.title;
      } else if (hasWhoFilters && hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHO_WHAT.title;
      } else if (hasWhoFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHO.title;
      } else if (hasWhatFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHAT.title;
      } else if (hasWhereFilters) {
        baseKey = SEO_TRANSLATIONS.GENRE_PERFORMANCES_WHERE.title;
      }

      return [
        {
          key: baseKey,
          params: {
            ...otherFilters,
            genre: mainEntityName,
          },
        },
      ];
    }

    if (hasSearchFilters) {
      const {
        [FILTER_TYPES.WHO]: whoProcessedFilters,
        [FILTER_TYPES.GENRE]: genreProcessedFilters,
        [FILTER_TYPES.WHAT]: whatProcessedFilters,
        [FILTER_TYPES.WHERE]: whereProcessedFilters,
      } = this.#processedFilters;

      const sortedFilters = [
        whoProcessedFilters,
        whereProcessedFilters,
        whatProcessedFilters,
        genreProcessedFilters,
      ].filter(Boolean);

      const dateFilter = this.#getDateFilterString(filters[FILTER_TYPES.DATE]);

      return [
        ...sortedFilters.map(values => ({
          key: values,
        })),
        ...(dateFilter ? [{ key: dateFilter }] : []),
      ];
    }

    return [
      {
        key: SEO_TRANSLATIONS.PRODUCTIONS.title,
        params: {
          filters,
        },
      },
    ];
  }

  #getPageEntityAndSearchDescription({ page, linkProps }) {
    const { entity, entityType, tab, searchable } = page;
    const { basePath, filters } = linkProps || {};
    const hasAppliedFilters = Object.keys(filters || {}).length > 0;

    if (basePath === ROUTE_RESERVED_KEYWORDS.artists) {
      const profession = filters?.[FILTER_TYPES.PROFESSION]?.[0]?.entity;
      let descriptionKey;
      let params = {};
      let includeArtistDescDetails = false;

      if (!profession) {
        const artistsString = this.#globalStats.artists?.toString() || '';
        const count = artistsString ? Math.round(Number(artistsString.replace(/[^\d]/g, '')) / 1000) * 1000 : '';

        descriptionKey = SEO_TRANSLATIONS.ARTISTS.description;
        params = { count };
      } else {
        includeArtistDescDetails = true;
        params = { profession: profession.name };

        if (profession.type === PROFESSION_TYPES.VOICE_TYPE) {
          descriptionKey = SEO_TRANSLATIONS.ARTISTS_VOICE_TYPE.description;
        } else if (profession.type === PROFESSION_TYPES.INSTRUMENTALIST) {
          descriptionKey = SEO_TRANSLATIONS.ARTISTS_INSTRUMENTALIST.description;
        } else {
          descriptionKey = SEO_TRANSLATIONS.ARTISTS_OTHER.description;
        }
      }

      const result = [
        {
          key: descriptionKey,
          params,
        },
      ];

      if (includeArtistDescDetails) {
        result.push({
          key: RESERVED_TRANSLATIONS.ARTIST_DESC_DETAILS,
        });
      }

      return result;
    }

    if (basePath === ROUTE_RESERVED_KEYWORDS.organisations) {
      const cityFilter = filters?.[FILTER_TYPES.CITY]?.[0];
      const countryFilter = filters?.[FILTER_TYPES.COUNTRY]?.[0];
      const orgType = filters?.[FILTER_TYPES.ORGANIZATION_TYPE]?.[0]?.entity;

      const city = cityFilter?.entity;
      const country =
        countryFilter?.entity ||
        cityFilter?.parents?.find(parent => parent.entityType === ENTITY_TYPES.COUNTRY)?.entity;

      let descriptionKey;
      let params = {};

      const orgsString = this.#globalStats.organizations?.toString() || '';
      const count = orgsString ? Math.round(Number(orgsString.replace(/[^\d]/g, '')) / 1000) * 1000 : '';

      if (orgType) {
        if (country || city) {
          descriptionKey = SEO_TRANSLATIONS.ORGANIZATIONS_ORG_TYPE_LOCATION.description;

          let location = '';
          if (city && country) {
            location = `${city.name}, ${country.name}`;
          } else if (city) {
            location = city.name;
          } else if (country) {
            location = country.name;
          }

          params = {
            orgType: orgType.name,
            location,
            count,
          };
        } else {
          descriptionKey = SEO_TRANSLATIONS.ORGANIZATIONS_ORG_TYPE.description;
          params = {
            orgType: orgType.name,
            count,
          };
        }
      } else {
        descriptionKey = SEO_TRANSLATIONS.ORGANIZATIONS.description;
        params = { count };
      }

      return [
        {
          key: descriptionKey,
          params,
        },
      ];
    }

    if (basePath === ROUTE_RESERVED_KEYWORDS.managers) {
      if (page?.entity) {
        return [
          {
            key: page.entity.name,
          },
          {
            key: RESERVED_TRANSLATIONS.MANAGER_DESC_DETAILS,
          },
        ];
      }

      const country = filters?.[FILTER_TYPES.COUNTRY]?.[0]?.entity;
      let descriptionKey;
      let params = {};

      if (country) {
        descriptionKey = SEO_TRANSLATIONS.MANAGERS_COUNTRY.description;
        params = { managerCountry: country.name };
      } else {
        descriptionKey = SEO_TRANSLATIONS.MANAGERS.description;
        params = {};
      }

      return [
        {
          key: descriptionKey,
          params,
        },
      ];
    }

    if (searchable) {
      return this.#getProductionListingDesc({ filters, page });
    }

    if (entityType && !!entity?.id) {
      switch (tab) {
        default: {
          if (tab) {
            return [
              {
                key: RESERVED_TRANSLATIONS.ENTITY_TAB,
                params: {
                  entity: entity.name,
                  tab: {
                    key: ROUTE_CONFIGURATIONS[tab]?.translationKey,
                  },
                },
              },
            ];
          }

          return [
            {
              key: entity?.name,
            },
          ];
        }
      }
    }

    if (!basePath && hasAppliedFilters) {
      return [
        {
          key: SEO_TRANSLATIONS.PRODUCTIONS.title,
          params: {
            filters,
          },
        },
      ];
    }

    if (basePath) {
      return [
        {
          key: basePath,
        },
      ];
    }

    return [
      {
        key: SEO_TRANSLATIONS.HOMEPAGE.description,
        params: this.#globalStats,
      },
    ];
  }

  #getSecondaryDescriptionParts({ page }) {
    const { tab, entity, entityType } = page;

    const hasTickets = this.#hasTickets;
    const hasWatchOnline = this.#hasWatchOnline;
    const hasUpcomingPerformances = this.#hasUpcomingPerformances;

    const parts = [];

    if (hasTickets || hasUpcomingPerformances) {
      parts.push({
        key: RESERVED_TRANSLATIONS.GET_TICKETS_FOR_UPCOMING,
        params: { count: page?.entity?.stats?.performances?.future?.total || '' },
      });
    }

    if (hasWatchOnline) {
      parts.push({
        key: RESERVED_TRANSLATIONS.WATCH_ONLINE_TAG,
      });
    }

    if (!tab && entityType === ENTITY_TYPES.PROFILE && !!entity?.id) {
      parts.push({
        key: RESERVED_TRANSLATIONS.CONTACT_REPRESENTATION_DETAILS,
      });
    }

    return parts;
  }

  getDescription({ page, linkProps, skipTitleObSuffix = false }) {
    const pageEntityAndSearchTitle = this.#getPageEntityAndSearchDescription({ page, linkProps });

    const secondaryDescriptionParts = this.#getSecondaryDescriptionParts({ page });

    const parts = [...pageEntityAndSearchTitle, ...secondaryDescriptionParts];

    if (!skipTitleObSuffix && parts.length < 3) {
      parts.push({
        key: RESERVED_TRANSLATIONS.OPERABASE,
      });
    }

    return parts;
  }
}

module.exports = PageDescResolver;
