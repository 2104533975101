const { chunk } = require('lodash');
const { TP, OPERATORS } = require('../constants');
const getEntityName = require('./getEntityName');

const BOOLEAN_TRANSLATION_KEYS = {
  TWO_ENTITIES_OR: `${TP}.FN_BOOLEAN_TWO_ENTITIES_OR`,
  THREE_ENTITIES_OR: `${TP}.FN_BOOLEAN_THREE_ENTITIES_OR`,
  TWO_ENTITIES_AND: `${TP}.FN_BOOLEAN_TWO_ENTITIES_AND`,
  THREE_ENTITIES_AND: `${TP}.FN_BOOLEAN_THREE_ENTITIES_AND`,
};

const getEntityNameArray = ({ entities = [], withDetails = false }) =>
  entities.map(item => getEntityName(item, { withDetails }));

const groupEntities = (entities = []) => {
  if (!entities.length) {
    return [];
  }

  const initialOperator = entities[1]?.operator || OPERATORS.AND;

  const { groups, currentGroup, currentOp } = entities.slice(1).reduce(
    (acc, entity) => {
      const op = entity.operator || OPERATORS.AND;

      if (op === acc.currentOp) {
        return {
          ...acc,
          currentGroup: [...acc.currentGroup, entity],
        };
      }

      return {
        groups: [...acc.groups, { operator: acc.currentOp, entities: acc.currentGroup }],
        currentGroup: [entity],
        currentOp: op,
      };
    },
    { groups: [], currentGroup: [entities[0]], currentOp: initialOperator },
  );

  return [...groups, { operator: currentOp, entities: currentGroup }];
};

const formatOrGroup = (entities, { withDetails = false } = {}) => {
  const names = getEntityNameArray({ entities, withDetails });

  if (names.length === 1) {
    return names[0];
  }

  if (names.length === 2) {
    return {
      key: BOOLEAN_TRANSLATION_KEYS.TWO_ENTITIES_OR,
      params: { entityA: names[0], entityB: names[1] },
    };
  }
  if (names.length === 3) {
    return {
      key: BOOLEAN_TRANSLATION_KEYS.THREE_ENTITIES_OR,
      params: { entityA: names[0], entityB: names[1], entityC: names[2] },
    };
  }

  const chunks = chunk(names, 3);

  const formattedChunks = chunks.map(chunkGroup =>
    formatOrGroup(
      chunkGroup.map(name => ({ entity: { name } })),
      { withDetails },
    ),
  );

  return formattedChunks.reduce((acc, cur) => ({
    key: BOOLEAN_TRANSLATION_KEYS.TWO_ENTITIES_OR,
    params: { entityA: acc, entityB: cur },
  }));
};

const joinAndGroupEntities = (entities, { withDetails = false } = {}) => {
  const names = getEntityNameArray({ entities, withDetails });

  if (names.length === 1) {
    return names;
  }

  if (names.length === 2) {
    return [names[0], names[1]];
  }

  return [names.slice(0, names.length - 1).join(', '), names[names.length - 1]];
};

const formatAndGroup = (entities, { withDetails = false } = {}) => {
  const names = getEntityNameArray({ entities, withDetails });

  if (names.length === 1) {
    return names[0];
  }

  const [firstPart, secondPart] = joinAndGroupEntities(entities, { withDetails });

  return {
    key: BOOLEAN_TRANSLATION_KEYS.TWO_ENTITIES_AND,
    params: { entityA: firstPart, entityB: secondPart },
  };
};

const combineGroups = (formattedGroups, groupsData) =>
  formattedGroups.slice(1).reduce((acc, cur, index) => {
    const op = groupsData[index + 1].operator;

    return op === OPERATORS.AND
      ? { key: BOOLEAN_TRANSLATION_KEYS.TWO_ENTITIES_AND, params: { entityA: acc, entityB: cur } }
      : { key: BOOLEAN_TRANSLATION_KEYS.TWO_ENTITIES_OR, params: { entityA: acc, entityB: cur } };
  }, formattedGroups[0]);

const getBooleanTranslations = (entities, { withDetails = false } = {}) => {
  if (!entities || entities.length === 0) {
    return [];
  }

  const groups = groupEntities(entities);

  const formattedGroups = groups.map(group => {
    if (group.operator === OPERATORS.OR) {
      return formatOrGroup(group.entities, { withDetails });
    }

    return formatAndGroup(group.entities, { withDetails });
  });

  if (formattedGroups.length === 1) {
    return formattedGroups[0];
  }

  const combined = combineGroups(formattedGroups, groups);
  return combined;
};

module.exports = getBooleanTranslations;
