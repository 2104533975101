/* eslint-disable no-dupe-class-members */
const dayjs = require('../../server/utils/dayjs');

const LinkGenerator = require('./LinkGenerator');
const PageTitleResolver = require('./PageTitleResolver');
const PageDescResolver = require('./PageDescResolver');
const { getPagePathnameWithLanguage } = require('../utils');
const getDateRangeLabel = require('../utils/getDateRangeLabel');
const getBooleanTranslations = require('../utils/getBooleanTranslations');
const { FILTER_TYPES, DEFAULT_LANGUAGE, ROUTE_RESERVED_KEYWORDS } = require('../constants');
const { ROUTE_CONFIGURATIONS, SPECIAL_KEYWORD_CONTEXT_PARAMS } = require('../constants/configurations');
const { RESERVED_TRANSLATIONS } = require('../constants/seoTranslations');

class MetaDetailsResolver {
  #language = DEFAULT_LANGUAGE;

  #today = null;

  #specialDateLabels = [];

  #pathname = null;

  #valid = false;

  #linkProps = {};

  #basePathAllowedWithFilters = false;

  #page = null;

  #processedFilters = {};

  #hasTickets = false;

  #hasWatchOnline = false;

  #hasUpcomingPerformances = false;

  #skipTitleObSuffix = false;

  #globalStats = {};

  constructor(state, { entityType, entity, globalStats = {} } = {}) {
    const { language, url, valid, linkProps, page } = state || {};

    this.#language = language;
    this.#today = dayjs();
    this.#specialDateLabels = Object.values(SPECIAL_KEYWORD_CONTEXT_PARAMS).reduce((acc, { type, translationKey }) => {
      if (type === FILTER_TYPES.DATE) {
        acc.push(translationKey);
      }

      return acc;
    }, []);

    this.#pathname = getPagePathnameWithLanguage(url, language);
    this.#valid = valid;
    this.#linkProps = linkProps;
    this.#page = {
      ...page,
      ...(entityType && { entityType }),
      ...(entity?.id && { entity }),
    };

    const { basePath } = linkProps || {};
    this.#basePathAllowedWithFilters = basePath ? ROUTE_CONFIGURATIONS[basePath]?.allowedWithFilters : true;

    this.#processedFilters = Object.keys(linkProps.filters || {}).reduce((acc, key) => {
      const value = linkProps.filters[key];

      if (key === FILTER_TYPES.DATE) {
        return {
          ...acc,
          when: this.#getDateFilterString(value),
        };
      }

      if (Array.isArray(value)) {
        const booleanString = getBooleanTranslations(value);

        if (booleanString) {
          const processedValue = typeof booleanString === 'string' ? { key: booleanString } : booleanString;

          if (processedValue) {
            const processedKey = key === FILTER_TYPES.WORK_TYPE ? 'workType' : key;

            return {
              ...acc,
              [processedKey]: processedValue,
            };
          }
        }
      }

      return acc;
    }, {});

    const { stats, tab } = page || {};

    if (!tab || tab === ROUTE_RESERVED_KEYWORDS.performances) {
      this.#hasTickets = stats?.tickets > 0;
    }

    if (!tab || [ROUTE_RESERVED_KEYWORDS.performances, ROUTE_RESERVED_KEYWORDS.videos].includes(tab)) {
      this.#hasWatchOnline = stats?.online > 0;
    }

    if (!tab || tab === ROUTE_RESERVED_KEYWORDS.performances) {
      this.#hasUpcomingPerformances = entity?.stats?.performances?.future?.exists || false;
    }

    this.#skipTitleObSuffix = state?.paths.length === 0;
    this.#globalStats = globalStats;
  }

  #getDateFilterString(dateFilters) {
    if (!dateFilters) {
      return '';
    }

    const label = getDateRangeLabel({ dateFrom: dateFilters.date_from, dateTo: dateFilters.date_to });

    if (this.#specialDateLabels.includes(label)) {
      return {
        key: label,
      };
    }

    return {
      key: RESERVED_TRANSLATIONS.DATE_IN_BRACKETS,
      params: {
        date: {
          key: label,
        },
      },
    };
  }

  #getTitle() {
    const pageTitleResolver = new PageTitleResolver({
      getDateFilterString: this.#getDateFilterString,
      processedFilters: this.#processedFilters,
      hasTickets: this.#hasTickets,
      hasWatchOnline: this.#hasWatchOnline,
      hasUpcomingPerformances: this.#hasUpcomingPerformances,
      globalStats: this.#globalStats,
    });
    const title = pageTitleResolver.getTitle({
      page: this.#page,
      linkProps: this.#linkProps,
      skipTitleObSuffix: this.#skipTitleObSuffix,
    });

    return title;
  }

  #getDescription() {
    const pageTitleResolver = new PageDescResolver({
      getDateFilterString: this.#getDateFilterString,
      processedFilters: this.#processedFilters,
      hasTickets: this.#hasTickets,
      hasWatchOnline: this.#hasWatchOnline,
      hasUpcomingPerformances: this.#hasUpcomingPerformances,
      globalStats: this.#globalStats,
    });
    const description = pageTitleResolver.getDescription({
      page: this.#page,
      linkProps: this.#linkProps,
      skipTitleObSuffix: this.#skipTitleObSuffix,
    });

    return description;
  }

  #getOgTitle() {
    return '';
  }

  #getOgDescription() {
    return '';
  }

  #getOgImages() {
    const defaultOgImage = {
      url: getPagePathnameWithLanguage('/images/operabase-logo-large.png'),
      alt: 'Operabase logo',
    };

    return defaultOgImage;
  }

  #getCanonical() {
    if (!this.#valid) {
      return this.#pathname;
    }

    const language = this.#language;
    const { filters, basePath } = this.#linkProps || {};

    let validFilters = filters;

    if (!basePath || basePath === ROUTE_RESERVED_KEYWORDS.productions) {
      const {
        [FILTER_TYPES.WHO]: whoFilter,
        [FILTER_TYPES.WHAT]: whatFilter,
        [FILTER_TYPES.WHERE]: whereFilter,
        [FILTER_TYPES.GENRE]: workTypeFilter,
        [FILTER_TYPES.DATE]: dateFilter,
      } = filters || {};

      validFilters = {
        ...(whoFilter && { [FILTER_TYPES.WHO]: whoFilter }),
        ...(whatFilter && { [FILTER_TYPES.WHAT]: whatFilter }),
        ...(whereFilter && { [FILTER_TYPES.WHERE]: whereFilter }),
        ...(workTypeFilter && { [FILTER_TYPES.GENRE]: workTypeFilter }),
        ...(dateFilter && { [FILTER_TYPES.DATE]: dateFilter }),
      };
    }

    const linkGenerator = new LinkGenerator({ language });
    const { url } = linkGenerator.getLinkProps({
      ...this.#linkProps,
      filters: validFilters,
      pro: false,
      edit: false,
    });

    return url.split('?')[0];
  }

  #shouldSkipIndex(canonical) {
    if (!this.#valid) {
      return true;
    }

    return this.#pathname !== canonical || !this.#basePathAllowedWithFilters;
  }

  #shouldSkipFollow() {
    const { pro, edit } = this.#page || {};

    if (!this.#valid || pro || edit) {
      return true;
    }

    return false;
  }

  getDetails() {
    const canonical = this.#getCanonical();

    return {
      title: this.#getTitle(),
      description: this.#getDescription(),
      canonical,
      noindex: this.#shouldSkipIndex(canonical),
      nofollow: this.#shouldSkipFollow(),
      robotsProps: {
        notranslate: true,
        noarchive: true,
      },
      openGraph: {
        title: this.#getOgTitle(),
        description: this.#getOgDescription(),
        images: this.#getOgImages(),
        url: canonical,
        type: 'website',
        locale: this.#language,
        siteName: 'Operabase',
      },
    };
  }
}

module.exports = MetaDetailsResolver;
